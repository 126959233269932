import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';
import * as Color from 'color';

import { UploadConfig, UploadConfigCssVars, uploadConfigCtaBackgroundColor, uploadConfigThemeProps } from './upload.model';
import Upload404 from './upload-404.component';
import UploadFormContainer from './upload-form-container.component';
import StorageService from './storage';


const styles: { [key: string]: CSSProperties } = {
    container: {
        backgroundColor: 'var(--upload-base-background-color)',
        color: 'var(--upload-base-foreground-color)'
    }
};

const UploadPage = (): ReactElement => {
    const [ slug, setSlug ] = useState<string>(null);
    const [ isEmbed, setIsEmbed ] = useState<boolean>(false);
    const [ config, setConfig ] = useState<UploadConfig>(null);
    const [ notFound, setNotFound ] = useState(false);
    const [ storageService ] = useState(new StorageService());

    // Initialize slug and isEmbed from url
    useEffect(() => {
        const slugFromUrl = window.location.pathname.slice(1);
        if (slugFromUrl.length) {
            setSlug(slugFromUrl);
        } else {
            setNotFound(true);
        }

        setIsEmbed(window.location.search.includes('embed=true'));
    }, []);

    // Load config
    useEffect(() => {
        if (slug) {
            const fetchSlug = async () => {
                try {
                    const result = await storageService.getConfig(slug);
                    storageService.isPreview = result.preview;
                    setConfig(result);
                } catch (e) {
                    setNotFound(true);
                }
            };

            fetchSlug();
        }
    }, [ slug ]);

    // Set theme vars based on config
    useEffect(() => {
        if (config) {
            Object.entries(config).forEach(([key, value]) => {
                if (uploadConfigThemeProps.includes(key) && value) {
                    document.documentElement.style.setProperty(UploadConfigCssVars[key], value);

                    if (key === uploadConfigCtaBackgroundColor) {
                        // Calculate hover/active colors
                        document.documentElement.style.setProperty(
                            UploadConfigCssVars.ctaBackgroundColorHover, Color(value).darken(.1).hex()
                        );
                        document.documentElement.style.setProperty(
                            UploadConfigCssVars.ctaBackgroundColorActive, Color(value).darken(.2).hex()
                        );
                    }
                }
            });
        }
    }, [ config ]);


    // Initial page will be  a loading state
    let page = (
        <div className="flex w-screen h-screen items-center justify-center">
            <div className="text-center">
                <i className="text-6xl gf-fly text-green-400"/>
                <h1 className="flex items-center font-black text-3xl lg:text-5xl mt-2 lg:mt-3">
                    <div className="spinner spinner-4 w-12 h-12 mr-5" />
                    <span>Loading&hellip;</span>
                </h1>
            </div>
        </div>
    );

    if (notFound) {
        page = <Upload404/>;
    } else if (config) {
        page = <UploadFormContainer config={config} isEmbed={isEmbed} storageService={storageService} />;
    }

    return (
        <div className="transition-colors duration-700 ease-in-out min-h-screen" style={styles.container}>
            {page}
        </div>
    );
};

export default UploadPage;
