import React, { ReactElement, useState } from 'react';
import logo from '../assets/img/logo-horizontal.png';
import classNames from 'classnames';

const UploadPreviewBanner = (): ReactElement => {
    const [ dismissing, setDismissing ] = useState(false);
    const [ dismissed, setDismissed ] = useState(false);

    function dismiss() {
        setDismissing(true);
        setTimeout(() => setDismissed(true), 200);
    }

    return (
        <div className={classNames(
            'relative bg-gray-800 text-white text-center text-sm py-2 px-12 font-medium italic transition transition-opacity duration-200',
            { 'opacity-0': dismissing, hidden: dismissed }
        )}
        >
            <img className="w-20 md:w-24 hidden md:block absolute left-4 lg:left-10 top-1/2 transform -translate-y-1/2"
                src={logo}
                alt="Greenfly"
            />

            Preview is simulated and no uploads will be captured

            <button
                className="absolute top-1/2 transform -translate-y-1/2 right-4 lg:right-10 hover:text-gray-400 transition-colors
                           duration-200"
                onClick={dismiss}
            >
                <span className="sr-only">Close</span>
                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                    aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>
    );
};

export default UploadPreviewBanner;
