const awsconfig = {
    Auth: {
        identityPoolId: 'us-east-1:18a6a0b2-6508-4127-b3f0-b4cfcb68d59e',
        region: 'us-east-1'
    },
    Storage: {
        AWSS3: {
            bucket: 'gf-fan-engagement',
            region: 'us-east-1'
        }
    }
};

export default awsconfig;
