import React, { ReactElement, useState } from 'react';
import { useMediaFileThumbnail } from './upload.hooks';

interface UploadFormFileInputProps {
    onChange?: (file: File) => void,
    accept?: string;
}

const UploadFormFileInput = ({ onChange, accept }: UploadFormFileInputProps): ReactElement => {
    const [ file, setFile ] = useState<File>(null);
    const thumbnail = useMediaFileThumbnail(file);

    const handleFileChange = (files: FileList | null) => {
        if (files && files[0]) {
            setFile(files[0]);
            onChange(files[0]);
        }
    };

    const fileInput = (
        <input type="file" id="fileInput" accept={accept} className="hidden" onChange={e => handleFileChange(e.target.files)}/>
    );

    if (!file) {
        return (
            <div className="shadow-xl rounded-lg">
                <label className="upload-btn block" htmlFor="fileInput" id="buttonChoose">
                    Select Media
                </label>

                {fileInput}
            </div>
        );
    }

    return (
        <div className="shadow-xl border-2 border-dotted border-gray-500 rounded-lg flex items-center justify-between">
            <div className="bg-gray-500 rounded-lg w-20 h-20 m-4 p-2 flex-shrink-0 relative flex items-center">
                <div className="py-1 px-2 bg-gray-400 text-xs text-black truncate rounded-md">{file.name}</div>

                { thumbnail &&
                    <img className="rounded-lg w-20 h-20 object-cover absolute left-0 top-0" src={thumbnail} alt="Your Media"/>
                }
            </div>

            <label className="upload-btn upload-btn-sm my-4 mx-6 w-40 flex-grow-0" htmlFor="fileInput" id="buttonChange">
                Change
            </label>

            {fileInput}
        </div>
    );
};

export default UploadFormFileInput;
