import React, { ReactElement } from 'react';
import * as Color from 'color';

import UploadLogo from './upload-logo.component';

interface UploadFormEmbedFooterProps {
    backgroundColor: string;
}

const UploadFormEmbedFooter = ({ backgroundColor }: UploadFormEmbedFooterProps): ReactElement => {
    const fadedBackgroundColor = Color(backgroundColor).fade(1).string();

    return (
        <>
            <div className="mt-12 relative text-center z-10"><UploadLogo /></div>
            <div className="fixed left-0 bottom-0 w-full pb-16 pointer-events-none"
                style={{
                    background: `linear-gradient(0deg, var(--upload-base-background-color) 28%, ${fadedBackgroundColor} 100%)`
                }}
            />
        </>
    );
};

export default UploadFormEmbedFooter;
