import React, { ReactElement } from 'react';
import UploadPage from './upload/upload-page.component';

function App(): ReactElement {
    return (
        <UploadPage />
    );
}

export default App;
