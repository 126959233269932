import React, { ReactElement } from 'react';

import { anyMediaRequested, noOrientation, UploadConfig } from './upload.model';

const UploadFormat = ({ config }: { config: UploadConfig }): ReactElement => {
    const formatSettings = [];

    if (config.mediaRequested !== anyMediaRequested) {
        formatSettings.push(
            <>
                <i className={`mr-2 text-2xl gf-${config.mediaRequested}`} style={ {color: 'var(--upload-cta-background-color)'} }/>
                {config.mediaRequested}
            </>
        );
    }

    if (config.orientation !== noOrientation) {
        formatSettings.push(
            <>
                <i className={`mr-2 text-2xl gf-${config.orientation}`} style={ {color: 'var(--upload-cta-background-color)'} }/>
                {config.orientation}
            </>
        );
    }

    if (config.duration) {
        formatSettings.push(
            <>
                <i className="mr-2 text-2xl gf-timer" style={ {color: 'var(--upload-cta-background-color)'} }/>
                {config.duration}
            </>
        );
    }


    if (!formatSettings.length) {
        return null;
    }

    return (
        <>
            <h3 className="text-lg font-bold mt-5">Suggested Format</h3>
            <p className="mt-2 flex items-center text-sm capitalize">
                {
                    formatSettings.map((setting, index) =>
                        <span className="inline-flex items-center" key={index}>
                            {setting}
                            { index !== formatSettings.length - 1 &&
                            <span className="mx-3 w-px h-4 bg-gray-400"/>
                            }
                        </span>
                    )
                }
            </p>
        </>
    );
};

export default UploadFormat;
