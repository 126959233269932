import React, { ChangeEvent, ReactElement } from 'react';
import { UploadConfig, UploadFormInput } from './upload.model';

interface UploadFormInputsProps {
    onChange: (input: Partial<UploadFormInput>) => void,
    input: UploadFormInput;
    config: UploadConfig;
}

const requiredStr = ' (required)';

const UploadFormInputs = ({ onChange, input, config }: UploadFormInputsProps): ReactElement => {
    function handleChange(e: ChangeEvent<HTMLInputElement>): void {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        onChange({ [e.target.name]: value });
    }

    return (
        <>
            {
                config.customFields.sort((a, b) => a.order - b.order).map(customField =>
                    <div className="mb-4" key={customField.order}>
                        { customField.type === 'date' &&
                            <label className="block text-sm mb-2" htmlFor={`input_${customField.order}`}>
                                {customField.label}
                                { customField.required && requiredStr }
                            </label>
                        }

                        { customField.type !== 'checkbox' &&
                            <input id={`input_${customField.order}`}
                                className="upload-form-control"
                                name={customField.label}
                                type={customField.type}
                                value={input[customField.label] as string}
                                placeholder={`${customField.label}${customField.required ? requiredStr : ''}`}
                                required={customField.required ? true : null}
                                onChange={handleChange}
                            />
                        }

                        { customField.type === 'checkbox' &&
                            <>
                                <input id={`input_${customField.order}`}
                                    name={customField.label}
                                    type="checkbox"
                                    checked={input[customField.label] as boolean}
                                    required={customField.required ? true : null}
                                    onChange={handleChange}
                                />

                                <label className="ml-2" htmlFor={`input_${customField.order}`}>
                                    {customField.label}
                                    { customField.required && requiredStr }
                                </label>
                            </>
                        }
                    </div>
                )
            }
        </>
    );
};

export default UploadFormInputs;
