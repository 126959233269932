import React, { ReactElement } from 'react';
import { UploadFormInput } from './upload.model';

interface UploadFormTosProps {
    required: boolean;
    disabledOnEmbed: boolean;
    isEmbed: boolean;
    accepted: boolean;
    tosUrl: string;
    onChange: (input: Partial<UploadFormInput>) => void
}

const UploadFormTos = ({ required, disabledOnEmbed, isEmbed, accepted, tosUrl, onChange }: UploadFormTosProps): ReactElement => {
    return (
        <>
            { !isEmbed || !disabledOnEmbed ?
                <div className="mt-4 mb-4 tracking-tight sm:tracking-normal">
                    { !required ?
                        <>By clicking Upload you agree to the {' '}</> :
                        <>
                            <input id="tos-required"
                                name="tos-required"
                                type="checkbox"
                                checked={accepted}
                                required
                                onChange={(e) => onChange({ termsAcknowledged: e.target.checked })}
                            />
                            <label className="ml-2" htmlFor="tos-required">I have read and agree to the</label>{' '}
                        </>
                    }
                    <a className="upload-link font-bold underline"
                        href={ tosUrl || 'https://portal.greenfly.com/termsofservice-engage' }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                terms and conditions
                    </a>.
                </div> : null }
        </>
    );
};

export default UploadFormTos;
