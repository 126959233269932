import { useEffect, useState } from 'react';


export const useMediaFileThumbnail = (file: File): string => {
    const [ thumbnail, setThumbnail ] = useState('');

    useEffect(() => {
        if (file) {
            if (file.type.startsWith('image/')) {
                getImageThumbnail(file);
            } else if (file.type === 'video/mp4') {
                getMp4VideoThumbnail(file);
            } else {
                setThumbnail('');
            }
        } else {
            setThumbnail('');
        }
    }, [ file ]);


    function getImageThumbnail(imageFile: File): void {
        const reader = new FileReader();

        reader.addEventListener('load', (e) => {
            if (e.target) {
                setThumbnail(e.target.result as string);
            }
        });

        // read the image file as a data URL.
        reader.readAsDataURL(imageFile);
    }

    function getMp4VideoThumbnail(videoFile: File): void {
        const videoUrl = URL.createObjectURL(videoFile);
        const videoEl = document.createElement('video');
        const canvasEl = document.createElement('canvas');
        const canvasCtx = canvasEl.getContext('2d');

        videoEl.muted = true;
        videoEl.controls = false;
        videoEl.volume = 0;
        videoEl.preload = 'metadata';
        videoEl.setAttribute('src', videoUrl);
        videoEl.load();
        videoEl.addEventListener('loadedmetadata', metaDataHandler);

        // Setup canvas for thumbnail capture
        function metaDataHandler() {
            // Set canvas dimensions same as video dimensions
            canvasEl.width = videoEl.videoWidth;
            canvasEl.height = videoEl.videoHeight;

            videoEl.currentTime = 1;
            videoEl.addEventListener('timeupdate', timeUpdateHandler);
        }

        // Draw thumbnail and set thumbnail state
        function timeUpdateHandler() {
            if (canvasCtx && videoEl.videoWidth && videoEl.videoHeight) {
                canvasCtx.drawImage(videoEl, 0, 0, videoEl.videoWidth, videoEl.videoHeight);
                setThumbnail(canvasEl.toDataURL('image/png'));

                videoEl.removeEventListener('loadedmetadata', metaDataHandler);
                videoEl.removeEventListener('timeupdate', timeUpdateHandler);
            }
            URL.revokeObjectURL(videoUrl);
        }
    }

    return thumbnail;
};
