import React, { ReactElement } from 'react';
import logo from '../assets/img/logo-horizontal.png';

const UploadLogo = (): ReactElement => {
    return (
        <div className="text-xs font-medium">
            Powered by
            <a href="https://greenfly.com" target="_blank" rel="noopener noreferrer">
                <img className="w-20 md:w-24 ml-2 inline-block align-middle" src={logo} alt="Greenfly"/>
            </a>
        </div>
    );
};

export default UploadLogo;
