import Amplify, { Storage } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { UploadConfig } from './upload.model';
import { v1 as uuidv1 } from 'uuid';


interface UploadParams {
    slug: string;
    file: File;
    metaData: { [key: string]: string };
    progressCallback: (percentage: number) => void;
    termsAcknowledged: boolean;
}

class StorageService {
    isPreview = false;
    private isLocal = true;
    private uploadSimulateDuration = 5000;

    constructor() {
        if (process.env.NODE_ENV === 'production') {
            Amplify.configure(awsconfig);
            this.isLocal = false;
        }
    }

    async getConfig(slug: string): Promise<UploadConfig> {
        return this.isLocal ? this.getConfigLocal(slug) : this.getConfigAws(slug);
    }

    async upload(params: UploadParams): Promise<boolean> {
        return (this.isLocal || this.isPreview) ? this.simulateUpload(params) : this.uploadAws(params);
    }

    private async getConfigAws(slug: string): Promise<UploadConfig> {
        return Storage.get(slug + '.json', {
            level: 'public',
            download: true,
            cacheControl: 'no-cache'
        })
            .then(result => new Response(result['Body'] as Blob).json());
    }

    private async getConfigLocal(slug: string): Promise<UploadConfig> {
        return fetch(`/testConfig/${slug}.txt`)
            .then(response => response.text())
            .then(responseBodyAsText => {
                try {
                    const bodyAsJson = JSON.parse(responseBodyAsText);
                    return bodyAsJson;
                } catch (e) {
                    return Promise.reject('Config not found!');
                }
            });
    }

    private async uploadAws({ metaData, slug, termsAcknowledged, progressCallback, file }: UploadParams): Promise<boolean> {
        const uploadId = uuidv1();
        const uploadData = { slug, metaData, termsAcknowledged };

        // First, we upload a JSON file using the media file name and write the metadata to it. Once done, then we upload the media file.
        // The metadata upload file happens so fast, we just ignore the progress and only use the progress of the media file upload when
        // calling the `progressCallback`.
        return Storage.put(
            `${uploadId}/${file.name}.json`,
            JSON.stringify(uploadData),
            {
                level: 'private',
                contentType: 'text/json'
            }
        )
            .then(() => Storage.put(
                `${uploadId}/${file.name}`,
                file,
                {
                    level: 'private',
                    progressCallback: (progress) => progressCallback((Math.round(progress.loaded/progress.total * 100)))
                }
            ))
            .then(() => true)
        ;
    }

    private async simulateUpload({ metaData, slug, progressCallback, file }: UploadParams): Promise<boolean> {
        if (this.isLocal) {
            // eslint-disable-next-line no-console
            console.log('Do upload', {slug, metaData, file});
        }

        if (progressCallback) {
            const timeout = this.uploadSimulateDuration/25;
            let pct = 0;

            const callback = () => {
                pct = Math.min(pct + 4, 100);

                progressCallback(pct);

                if (pct < 100) {
                    setTimeout(callback, timeout);
                }
            };

            callback();
        }

        return new Promise<boolean>((resolve, reject) => {
            // To coerce an upload error, choose a file with a name that starts with the string 'error'
            if (file.name.startsWith('error')) {
                setTimeout(reject, this.uploadSimulateDuration);
            } else {
                setTimeout(() => resolve(true), this.uploadSimulateDuration);
            }
        });
    }
}

// export default service;
export default StorageService;
