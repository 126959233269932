import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/styles/app.dist.css';
import './assets/fonts/greenfly-fan-engagement-app/style.css';

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
