import React, { ReactElement } from 'react';

const Upload404 = (): ReactElement => {
    return (
        <div className="flex w-screen h-screen items-center justify-center px-8">
            <div className="text-center">
                <i className="text-6xl gf-fly text-green-400"/>
                <h1 className="font-black text-3xl lg:text-5xl mt-2 lg:mt-3">Not Found!</h1>
                <p className="text-lg lg:text-xl">Sorry, we could not find the content you requested.</p>
            </div>
        </div>
    );
};

export default Upload404;
