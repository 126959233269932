import React, { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';

import { UploadConfig, UploadFormInput } from './upload.model';
import UploadFormHeader from '../upload-form-header.component';
import UploadDone from './upload-done.component';
import UploadForm from './upload-form.component';
import UploadFormEmbedFooter from './upload-form-embed-footer.component';
import StorageService from './storage';
import UploadPreviewBanner from './upload-preview-banner';


interface UploadFormProps {
    config: UploadConfig;
    isEmbed: boolean;
    storageService: StorageService;
}

const UploadFormContainer = ({ config, isEmbed, storageService }: UploadFormProps): ReactElement => {
    const [ formInput, setFormInput ] = useState<UploadFormInput>({
        file: null,
        termsAcknowledged: false
    });
    const [ done, setDone ] = useState(false);
    const [ formCompleted, setFormCompleted ] = useState(false);

    // Configure input data for custom fields
    useEffect(() => {
        if (config.customFields?.length) {
            const customFieldInput = config.customFields.reduce(
                (fieldObj, customField) => {
                    fieldObj[customField.label] = customField.type === 'checkbox' ? false : '';

                    return fieldObj;
                },
                {}
            );

            updateFormInput(customFieldInput);
        }
    }, [ config ]);

    function updateFormInput(partialInput: Partial<UploadFormInput>): void {
        setFormInput({ ...formInput, ...partialInput });
    }

    function updateFile(file: File): void {
        updateFormInput({ file });
    }

    function handleSuccess() {
        setDone(true);
        setFormCompleted(true);
    }

    function resetForAdditionalUpload(): void {
        setDone(false);
        updateFile(null);
    }


    return (<>
        { storageService.isPreview && <UploadPreviewBanner /> }

        { !isEmbed && <UploadFormHeader bannerUrl={config.bannerUrl} /> }

        <div className={classNames(
            'container mx-auto',
            {
                'mt-8 pb-8 md:mt-16 px-8 md:px-16': !isEmbed,
                'p-8': isEmbed
            }
        )}>
            { done ?
                <UploadDone thankYouMessage={config.thankYouMessage} reset={resetForAdditionalUpload} /> :
                <UploadForm
                    config={config}
                    input={formInput}
                    isEmbed={isEmbed}
                    formCompleted={formCompleted}
                    updateInput={updateFormInput}
                    updateFile={updateFile}
                    onSuccess={handleSuccess}
                    storageService={storageService}
                />
            }

            { isEmbed && <UploadFormEmbedFooter backgroundColor={config.backgroundColor} /> }
        </div>
    </>);
};

export default UploadFormContainer;
