import React, { ReactElement } from 'react';
import UploadLogo from './upload/upload-logo.component';

interface UploadFormHeaderProps {
    bannerUrl: string;
}

const UploadFormHeader = ({ bannerUrl }: UploadFormHeaderProps): ReactElement => {
    return (<>
        <div className="flex justify-center h-32 lg:h-48" style={ { backgroundColor: 'var(--upload-banner-color)' } }>
            {bannerUrl &&
            <div className="mx-auto bg-center bg-cover bg-no-repeat w-full"
                style={{backgroundImage: `url(${bannerUrl})`, maxWidth: '2000px'}}
            />
            }
        </div>

        <div className="container mx-auto mt-2 px-4 text-right">
            <UploadLogo />
        </div>
    </>);
};

export default UploadFormHeader;
