export interface UploadConfig {
    slug: string;
    backgroundColor: string;
    foregroundColor: string;
    ctaBackgroundColor: string;
    ctaForegroundColor: string;
    bannerUrl: string;
    bannerColor: string;
    title: string;
    description: string;
    thankYouMessage: string;
    expired: boolean;
    mediaRequested: string;
    orientation: string;
    duration: string;
    termsOfServiceUrl: string;
    termsOfServiceRequired: boolean;
    termsOfServiceDisabledForEmbed: boolean;
    customFields: CustomField[];
    preview: boolean;
}

export interface CustomField {
    order: number;
    label: string;
    type: string;
    required: boolean;
}

export const anyMediaRequested = 'any';
export const noOrientation = 'any';

export enum UploadConfigCssVars {
    backgroundColor = '--upload-base-background-color',
    foregroundColor = '--upload-base-foreground-color',
    ctaBackgroundColor = '--upload-cta-background-color',
    ctaBackgroundColorHover = '--upload-cta-background-color-hover',
    ctaBackgroundColorActive = '--upload-cta-background-color-active',
    ctaForegroundColor = '--upload-cta-foreground-color',
    bannerColor = '--upload-banner-color'
}
export const uploadConfigThemeProps = [
    'backgroundColor', 'foregroundColor', 'ctaBackgroundColor', 'ctaForegroundColor', 'bannerColor'
];
export const uploadConfigCtaBackgroundColor = 'ctaBackgroundColor';

export interface UploadFormInput {
    file: File;
    termsAcknowledged: boolean;
    [key: string]: string | File | boolean
}
